h2.typo-h2 {
    @apply text-body text-2xl lg:text-[34px] tracking-[0.25px] font-bold font-display;
}

h3.typo-h3 {
    @apply text-body lg:text-lg font-bold;
}

h4.typo-h4 {
    @apply text-body text-xl tracking-wide font-bold;
}

h5.typo-h5 {
    @apply text-primary text-base tracking-wide font-bold;
}

p.typo-subtitle {
    @apply text-base;
}
