// https://www.joshwcomeau.com/gradient-generator?colors=00484b|4cdde5|c1ff72&angle=25&colorMode=hsl&precision=4&easingCurve=0.5905172413793104|0.3105132004310345|-0.05|1.05
.gradient-cb1 {
  background-image: linear-gradient(
                  25deg,
                  hsl(182deg 100% 15%) 0%,
                  hsl(183deg 92% 30%) 14%,
                  hsl(183deg 83% 45%) 29%,
                  hsl(183deg 75% 60%) 49%,
                  hsl(151deg 83% 64%) 66%,
                  hsl(119deg 91% 68%) 83%,
                  hsl(86deg 100% 72%) 100%
  );
}


// https://www.gradient-animator.com/
.gradient-cb2 {
  background: linear-gradient(140deg, #119da4, #c1ff72, #4cdde5);
  background-size: 600% 600%;

  -webkit-animation: cloudbarOne 13s ease infinite;
  -moz-animation: cloudbarOne 13s ease infinite;
  animation: cloudbarOne 13s ease infinite;
}

@-webkit-keyframes cloudbarOne {
  0% {
    background-position: 96% 0%
  }
  50% {
    background-position: 5% 100%
  }
  100% {
    background-position: 96% 0%
  }
}

@-moz-keyframes cloudbarOne {
  0% {
    background-position: 96% 0%
  }
  50% {
    background-position: 5% 100%
  }
  100% {
    background-position: 96% 0%
  }
}

@keyframes cloudbarOne {
  0% {
    background-position: 96% 0%
  }
  50% {
    background-position: 5% 100%
  }
  100% {
    background-position: 96% 0%
  }
}

.gradient-cb3-cymk-small {
  background: linear-gradient(140deg, #00FFFF, #FF00FF, #FFFF00);
  background-size: 150% 150%;

  -webkit-animation: cloudbarOne 13s ease infinite;
  -moz-animation: cloudbarOne 13s ease infinite;
  animation: cloudbarOne 13s ease infinite;
}

.gradient-cb3-cymk {
  background: linear-gradient(140deg, #00FFFF, #FF00FF, #FFFF00);
  background-size: 600% 600%;

  -webkit-animation: cloudbarOne 13s ease infinite;
  -moz-animation: cloudbarOne 13s ease infinite;
  animation: cloudbarOne 13s ease infinite;
}

@-webkit-keyframes cloudbarOne {
  0% {
    background-position: 0% 0%
  }
  50% {
    background-position: 100% 0%
  }
  100% {
    background-position: 0% 0%
  }
}

@-moz-keyframes cloudbarOne {
  0% {
    background-position: 0% 0%
  }
  50% {
    background-position: 100% 0%
  }
  100% {
    background-position: 0% 0%
  }
}

@keyframes cloudbarOne {
  0% {
    background-position: 0% 0%
  }
  50% {
    background-position: 100% 0%
  }
  100% {
    background-position: 0% 0%
  }
}


//
/*
* Created with https://www.css-gradient.com
* Gradient link: https://www.css-gradient.com/?c1=119ea4&c2=ffffff&gt=r&gd=dcc
*/

.gradient-circle-cb2 {
  background: rgba(17, 158, 164, 1.0);
  background: radial-gradient(at center, rgba(17, 158, 164, 1.0), rgba(255, 255, 255, 1.0));
}


.buttonGradient {
  background-color: #000000;
  padding: 3px;
  background-image: linear-gradient(to right, #00FFFF, #FF00FF);
}

.buttonGradientNeutral {
  padding: 3px;
}


.buttonShine {
  filter: blur(12px);
  background-image: linear-gradient(to right, #00FFFF, #FF00FF, #FFFF00);
  border-radius: 10000000px;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}


.circleBlur1 {
  position: absolute;
  border-radius: 1000000px;
  filter: blur(70px);
  transform-origin: 50px 50px; /* Adjust this to change the center point */
  animation: circle-animation 20s infinite linear;
}

.circleBlur2 {
  position: absolute;
  border-radius: 1000000px;
  filter: blur(70px);
  transform-origin: 30px 40px; /* Adjust this to change the center point */
  animation: circle-animation-2 25s infinite linear;
}

@keyframes circle-animation {
  0% {
    transform: rotate(0deg) translateX(100px) rotate(0deg);
    opacity: 0.3;
  }
  50% {
    transform: rotate(180deg) translateX(100px) rotate(-180deg);
    opacity: 0.5;
  }
  100% {
    transform: rotate(360deg) translateX(100px) rotate(-360deg);
    opacity: 0.3;
  }
}


@keyframes circle-animation-2 {
  0% {
    transform: rotate(0deg) translateX(120px) rotate(0deg);
    opacity: 0.3;
    filter: blur(2px);
  }
  50% {
    transform: rotate(180deg) translateX(120px) rotate(-180deg);
    opacity: 0.5;
    filter: blur(5px);
  }
  100% {
    transform: rotate(360deg) translateX(120px) rotate(-360deg);
    opacity: 0.3;
    filter: blur(2px);
  }
}

