// RichTextDisplay.tsx
.cmsTitleStyles {
  @media (max-width: 1000px) {
    &.CMSImage16by9 {
      font-size: 1.5em;

      h1 {
        @apply font-bold text-lg leading-tight;
      }

      h2 {
        @apply font-bold text-[9px] leading-tight;
      }

      p {
        @apply text-[8px] leading-tight;
      }
    }

    &.CMSLayoutText1 {

      font-size: 1em;
      line-height: 1.7em;


      h1 {
        @apply font-bold text-xl leading-tight mt-4;
      }

      h2 {
        @apply font-bold text-lg leading-tight mb-1;
      }

      p {
        @apply text-base leading-tight;
      }

      li {
        @apply text-base leading-tight;
      }

    }
  }

  @media (min-width: 1001px) {
    &.CMSImage16by9 {

      h1 {
        @apply font-bold text-4xl leading-tight;
      }

      h2 {
        @apply font-bold text-2xl leading-tight;
      }

      p {
        @apply text-lg leading-tight;
      }

      ul, ol {
        li {
          @apply text-lg leading-tight;

        }
      }
    }

    &.CMSLayoutText1,
    &.CMSLayoutImageText1,
    &.CMSLayoutTextText1,
    &.CMSLayoutImageTextRow,
    &.CMSLayoutTextImage1 {

      h1 {
        @apply font-bold text-3xl leading-tight;
      }

      h2 {
        @apply font-bold text-2xl leading-tight;
      }

      p {
        @apply text-lg leading-tight;
        min-height: 22.5px;
      }

      li {
        @apply text-lg leading-tight;
      }
    }
  }
}


.cmsTitleInvertedStyles {
  @media (max-width: 1000px) {
    &.CMSImage16by9 {
      font-size: 1.5em;

      h1 {
        @apply font-bold text-[14px] leading-tight;
      }

      h2 {
        @apply font-bold text-[9px] leading-tight;
      }

      p {
        @apply text-[10px] leading-tight;
      }
    }
  }

  @media (min-width: 1001px) {
    &.CMSImage16by9 {

      h1 {
        @apply font-bold text-4xl leading-tight;
      }

      h2 {
        @apply font-bold text-2xl leading-tight;
      }

      p {
        @apply text-lg leading-tight;
      }

      li {
        @apply text-lg leading-tight;

      }
    }
  }
}
