.translationTag {

  // background color #119DA4 5%
  //background-color: rgba(17, 157, 164, 0.05) !important;
  //background-color: rgba(228, 156, 255, 0.22) !important;
  transition: all;
  cursor: pointer;

  @apply duration-300 bg-pink-400/10;

  &:hover {
    //background-color: rgba(194, 88, 255, 0.3) !important;
    //background-color: rgba(17, 157, 164, 0.3) !important;

    @apply ring-2 ring-offset-2 ring-pink-700 shadow-2xl rounded scale-[101%] bg-pink-600/10;

  }

}
