.pageStyles {

  h1, h2, h3, h4, h5, h6 {
    //margin: 0 0 20px 0 !important;
    padding: 0;
  }

  ul, ol, bulleted-list, numbered-list {
    margin: 20px 0 0 0 !important;
    padding: 0;
    font-size: 1.2em;
  }

  table {

    tr {
      padding: 3px 0;
    }
  }

  td:first-child {
    padding-right: 30px;
    font-weight: bold;
    vertical-align: top;
  }

  td:last-child {
    vertical-align: top;
  }

  p {
    font-size: 1.2em;
    color: #333333;
  }

  //button.button, a.button {
  //  margin: 20px 0 0 0 !important;
  //}
}
